export default function formatSmartIAResponse(text) {
    const clearText = text.replaceAll("{", "").replaceAll("}", "").replaceAll("!--", "").replaceAll("set", "\n\nset")
    console.log(clearText);
    return clearText;
}

export function formatDate(timestamp){
    // Convertir timestamp a milisegundos
    var date = new Date(timestamp);

    // Obtener hora y minutos
    var horas = date.getHours();
    var minutos = date.getMinutes();

    // Determinar si es AM o PM
    var am_pm = horas < 12 ? "AM" : "PM";

    // Convertir a formato de 12 horas
    horas = horas % 12;
    horas = horas ? horas : 12; // Si es 0, lo cambiamos a 12

    // Formatear la hora y los minutos con ceros a la izquierda si es necesario
    var hora_str = horas < 10 ? "0" + horas : horas;
    var minutos_str = minutos < 10 ? "0" + minutos : minutos;

    // Concatenar la hora y los minutos con el indicador AM/PM
    var hora_formato = hora_str + ":" + minutos_str + " " + am_pm;
    
    return hora_formato;
}