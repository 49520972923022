import React from 'react';
import "./styles.css";

import KnowledgeIA from "./Components/KnowledgeIA";
export default function Root(props) {
 

  // useEffect(() => {
  //   const chatArea = document.getElementById("chat-container");
  //   chatArea ? chatArea.scroll(0, 1000) : null
  // })


  return (
    <div className="genia-container">
      <KnowledgeIA />
    </div>
  );
}
