import React, { useState, useEffect, useRef } from "react";
import Md2HTML from "./SmartIA/Md2HTML";
import { formatDate } from "../Functions/Functions";
import apiService from "../services/services";

export default function KnowledgeIA({ consultGenIA }) {
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [body, setBody] = useState({
    request: "",
  });

  const chatContainer = useRef(null);
  const inputChat = useRef(null);

  useEffect(() => {
    const chargeChat = async () => {
      console.log(localStorage.getItem("chatbot"));
      if (localStorage.getItem("chatbot") !== null) {
        await setChatHistory(JSON.parse(localStorage.getItem("chatbot")));
      } else {
        let initMess = {
          type: "KnowledgeApp",
          text: "Hola soy tu asistente virtual en que te puedo ayudar el dia de hoy",
          time: formatDate(new Date().getTime()),
        };

        await setChatHistory([initMess]);
        console.log(chatHistory);
        await localStorage.setItem("chatbot", JSON.stringify([initMess]));
      }
      scrollBottom();
    };

    chargeChat();
  }, []);

  const scrollBottom = () => {
    if (chatContainer.current) {
      chatContainer.current.scrollTop = chatContainer.current.scrollHeight;
    }
  };

  const saveHistory = async (send) => {
    console.log(send);
    let history = JSON.parse(localStorage.getItem("chatbot"));
    await history.push(send);
    await localStorage.setItem("chatbot", JSON.stringify(history));
    await setChatHistory(history);
    
  };

  const handleKnowledgeIAConsumption = async () => {
    if (body.request != "") {
      let send = {
        type: "user",
        text: body.request,
        time: formatDate(new Date().getTime()),
      };
      setIsLoading(true);
      await saveHistory(send);
      const sendData = {
        question: body.request,
      };
      cleanChat();

      await apiService
        .sendQuestion(sendData)
        .then(async (response) => {
          await setIsLoading(false);
          let responseMessage = {
            type: "KnowledgeApp",
            text: response.answer,
            time: formatDate(new Date().getTime()),
          };
          await saveHistory(responseMessage);
          await scrollBottom();
        })
        .catch((error) => {
          console.error("Error:", error.message);
        });
   
      
    }
  };

  const cleanChat = () => {
    if (inputChat.current) {
      inputChat.current.textContent = ""; // Establecer el contenido del div como una cadena vacía
      setBody({
        request: "",
      });
    }
  };

  const handleChangeKnowledgeBody = (event) => {
    setBody({ request: event.target.innerText });
  };

  const keyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey && event.keyCode !== 10) {
      event.preventDefault();
      handleKnowledgeIAConsumption();
    }
  };

  const closeChat = () => {
    window.dispatchEvent(
      new CustomEvent("show_chat", {
        detail: {
          show: false,
        },
      })
    );
    const confirmacion = confirm(
      "¿Estás seguro de que deseas cerrar la conversación?"
    );
    if (confirmacion) {
      localStorage.removeItem("chatbot");
      cleanChat();
      window.parent.dispatchEvent(
        new CustomEvent("show_chat", {
          detail: {
            show: false,
          },
        })
      );
    }
  };

  return (
    <div className="app-container knowledgeApp-container">
      <div className="chat-header">
        <div className="avatar">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path d="M370.7 96.1C346.1 39.5 289.7 0 224 0S101.9 39.5 77.3 96.1C60.9 97.5 48 111.2 48 128v64c0 16.8 12.9 30.5 29.3 31.9C101.9 280.5 158.3 320 224 320s122.1-39.5 146.7-96.1c16.4-1.4 29.3-15.1 29.3-31.9V128c0-16.8-12.9-30.5-29.3-31.9zM336 144v16c0 53-43 96-96 96H208c-53 0-96-43-96-96V144c0-26.5 21.5-48 48-48H288c26.5 0 48 21.5 48 48zM189.3 162.7l-6-21.2c-.9-3.3-3.9-5.5-7.3-5.5s-6.4 2.2-7.3 5.5l-6 21.2-21.2 6c-3.3 .9-5.5 3.9-5.5 7.3s2.2 6.4 5.5 7.3l21.2 6 6 21.2c.9 3.3 3.9 5.5 7.3 5.5s6.4-2.2 7.3-5.5l6-21.2 21.2-6c3.3-.9 5.5-3.9 5.5-7.3s-2.2-6.4-5.5-7.3l-21.2-6zM112.7 316.5C46.7 342.6 0 407 0 482.3C0 498.7 13.3 512 29.7 512H128V448c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64l98.3 0c16.4 0 29.7-13.3 29.7-29.7c0-75.3-46.7-139.7-112.7-165.8C303.9 338.8 265.5 352 224 352s-79.9-13.2-111.3-35.5zM176 448c-8.8 0-16 7.2-16 16v48h32V464c0-8.8-7.2-16-16-16zm96 32a16 16 0 1 0 0-32 16 16 0 1 0 0 32z" />
          </svg>
        </div>
        <p>Asistente en línea </p>
        <div onClick={closeChat}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>
      <div ref={chatContainer} className="chat-container" id="chat-container">
        {chatHistory.map((item, i) => (
          <div className={`chat-input chat-${item.type}`} key={i}>
            <div className="cont-message">
              <h3 className={`chat-${item.type}-type chat-type`}>
                {item.type === "KnowledgeApp" ? "Asistente" : ""}
              </h3>
              <p>
                {" "}
                <Md2HTML text={item.text} />
              </p>
              <span>{item.time}</span>
            </div>

            <div className="avatar">
              {item.type == "user" ? (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm94.5 200.2l18.6 31L175.8 483.1l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7H162.5c0 0 0 0 .1 0H168 280h5.5c0 0 0 0 .1 0H417.3c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9L238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2H224 204.3c-12.4 0-20.1 13.6-13.7 24.2z" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M370.7 96.1C346.1 39.5 289.7 0 224 0S101.9 39.5 77.3 96.1C60.9 97.5 48 111.2 48 128v64c0 16.8 12.9 30.5 29.3 31.9C101.9 280.5 158.3 320 224 320s122.1-39.5 146.7-96.1c16.4-1.4 29.3-15.1 29.3-31.9V128c0-16.8-12.9-30.5-29.3-31.9zM336 144v16c0 53-43 96-96 96H208c-53 0-96-43-96-96V144c0-26.5 21.5-48 48-48H288c26.5 0 48 21.5 48 48zM189.3 162.7l-6-21.2c-.9-3.3-3.9-5.5-7.3-5.5s-6.4 2.2-7.3 5.5l-6 21.2-21.2 6c-3.3 .9-5.5 3.9-5.5 7.3s2.2 6.4 5.5 7.3l21.2 6 6 21.2c.9 3.3 3.9 5.5 7.3 5.5s6.4-2.2 7.3-5.5l6-21.2 21.2-6c3.3-.9 5.5-3.9 5.5-7.3s-2.2-6.4-5.5-7.3l-21.2-6zM112.7 316.5C46.7 342.6 0 407 0 482.3C0 498.7 13.3 512 29.7 512H128V448c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64l98.3 0c16.4 0 29.7-13.3 29.7-29.7c0-75.3-46.7-139.7-112.7-165.8C303.9 338.8 265.5 352 224 352s-79.9-13.2-111.3-35.5zM176 448c-8.8 0-16 7.2-16 16v48h32V464c0-8.8-7.2-16-16-16zm96 32a16 16 0 1 0 0-32 16 16 0 1 0 0 32z" />
                </svg>
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div>
            <p className="generating">El asistente esta escribiendo</p>
            <div id="wave">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          </div>
        )}
      </div>
      <div className="input-cont">
        <div className="input-layout">
          <div
            ref={inputChat}
            className="chat-input-container"
            contenteditable="true"
            onInput={handleChangeKnowledgeBody}
            onKeyDown={keyDown}
          ></div>
          <button onClick={handleKnowledgeIAConsumption}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-send"
              viewBox="0 0 16 16"
            >
              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
            </svg>
          </button>
        </div>

        {/* <textarea
          name="textarea"
          value={body.request}
          onChange={handleChangeKnowledgeBody}
        ></textarea>
         */}
      </div>
    </div>
  );
}
