import axios from "axios";

const API_URL = "https://api.pd.nttdataco.com/ms-skgenia/api"; // Reemplaza esto con la URL de tu API

let headers = {
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
};

const apiService = {
  // Función para hacer una petición POST a tu API

  sendQuestion: async (data) => {
    try {
      const response = await axios.post(`${API_URL}/messages`, data, {
        headers: {
          "Accept": "*/*",
          "Content-Type": "application/json",
        },
      });
    
      if (response.status == 200) {
        return response.data;
      } else {
        return response.data.error.message;
      }
     
    } catch (error) {
      // Maneja el error aquí
      console.error("Error al hacer la petición POST:", error);
      throw error; // Puedes relanzar el error para manejarlo en el componente que llame a esta función
    }
  },
};

export default apiService;
